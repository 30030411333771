@import url('https://fonts.googleapis.com/css?family=Nunito:600');


.site-header {
  margin-bottom: 0;
  border-radius: 0;
  animation: color-change 6s infinite;
  font-family: 'Nunito', sans-serif;
  font-size: 17pt;
}

.kfont {
  font-family: 'Nunito', sans-serif;
}

@keyframes color-change {
  0% { color: #00a5e6; }
  25% { color: rgb(255, 0, 179); }
  50% { color: rgb(255, 60, 0); }
  75% { color: rgb(142, 206, 23); }
  100% { color: #00a5e6; }
}