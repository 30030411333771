@import url(https://fonts.googleapis.com/css?family=Nunito:600);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-header {
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation: color-change 6s infinite;
          animation: color-change 6s infinite;
  font-family: 'Nunito', sans-serif;
  font-size: 17pt;
}

.kfont {
  font-family: 'Nunito', sans-serif;
}

@-webkit-keyframes color-change {
  0% { color: #00a5e6; }
  25% { color: rgb(255, 0, 179); }
  50% { color: rgb(255, 60, 0); }
  75% { color: rgb(142, 206, 23); }
  100% { color: #00a5e6; }
}

@keyframes color-change {
  0% { color: #00a5e6; }
  25% { color: rgb(255, 0, 179); }
  50% { color: rgb(255, 60, 0); }
  75% { color: rgb(142, 206, 23); }
  100% { color: #00a5e6; }
}
